<template>
  <div id="landing-page">
    <div
      @mousemove="updateCoordinates"
      :class="{
        visibleNavbar: !isOnTop,
        invisibleNavbar: isOnTop && !isInitLoad,
      }"
      id="navbar"
    >
      <div id="logo">
        <img src="../../assets/Full_logo.svg" alt />
      </div>
      <div
        v-if="(isLoggedIn && $screen.width > 950) || (!isLoggedIn && $screen.width > 1040)"
        id="navigation"
      >
        <h4 class="nav-item customers-section" @click="scrollTo('partners', 'center')">Πελάτες</h4>
        <h4
          class="nav-item how-it-works-section"
          @click="scrollTo('how-it-works', 'start')"
        >Πώς λειτουργεί</h4>
        <h4
          class="nav-item fluter-demo-section"
          @click="scrollTo('go-here-on-click-demo-at-nav', 'start')"
        >Demo</h4>
        <h4 class="nav-item fluter-benefits" @click="scrollTo('bubble4', 'center')">Οφέλη Fluter</h4>
        <h4 class="nav-item contact-us-section" @click="scrollTo('contact-us', 'start')">Επικοινωνία</h4>
        <!-- <ProfileDropdown v-if="isLoggedIn" id="profile-dropdown"></ProfileDropdown>
        <h4 class="nav-item" v-else @click="initiateEntrance">Σύνδεση</h4>-->
      </div>
      <div v-else id="mobile-navbar-container">
        <h4
          id="demo-mobile-nav-item"
          v-if="$screen.width > 290"
          class="demo-navbar-not-logged-in"
          @click="scrollTo('go-here-on-click-demo-at-nav', 'start')"
        >Demo</h4>
        <div id="mobile-navigation">
          <!-- <ProfileDropdown
            @toggle-profile-dropdown="toggleProfileDropdown"
            v-if="isLoggedIn"
            id="mobile-profile-dropdown"
          ></ProfileDropdown>-->
          <font-awesome-icon @click="toggleOptions" id="bars" icon="bars" />
          <div v-if="areOptionsOpen" id="navigation-options">
            <h4 @click="scrollTo('partners', 'center')" class="option">Πελάτες</h4>
            <h4 @click="scrollTo('how-it-works', 'start')" class="option">Πώς λειτουργεί</h4>
            <h4
              v-if="$screen.width <= 290"
              @click="scrollTo('go-here-on-click-demo-at-nav', 'start')"
              class="option"
            >Demo</h4>
            <h4 @click="scrollTo('bubble4', 'center')" class="option">Οφέλη Fluter</h4>
            <h4 @click="scrollTo('contact-us', 'start')" class="option">Επικοινωνία</h4>
            <!-- <h4 v-if="!isLoggedIn" @click="initiateEntrance" class="option">Σύνδεση</h4> -->
          </div>
        </div>
      </div>
    </div>
    <div @click="onMaskClick" :class="{
        mask: isEntranceOpen,
      }"></div>
    <div @mousemove="updateCoordinates" id="start-screen">
      <div
        :style="{
          transform: 'translate(' + this.x + 'px,' + this.y + 'px) !important',
        }"
        :class="{
          shape: true,
          shape2: true,
        }"
      ></div>
      <div
        :style="{
          transform: 'translate(' + this.x + 'px,' + this.y + 'px) !important',
        }"
        :class="{
          shape: true,
          shape3: true,
        }"
      ></div>
      <div
        :style="{
          transform: 'translate(' + this.x + 'px,' + this.y + 'px) !important',
        }"
        :class="{
          shape: true,
          shape6: true,
        }"
      ></div>
      <div
        :style="{
          transform: 'translate(' + this.x + 'px,' + this.y + 'px) !important',
        }"
        :class="{
          shape: true,
          shape4: true,
        }"
      ></div>
      <div
        :style="{
          transform: 'translate(' + this.x + 'px,' + this.y + 'px) !important',
        }"
        :class="{ shape: true, shape5: true }"
      ></div>
      <div id="copyright">
        <h1 data-aos="fade-right" data-aos-duration="1300" data-aos-delay="200" id="main-title">
          <span class="green">Το μέλλον της παραγγελίας</span>
          <br />στην οθόνη
          σου
        </h1>
        <h4 data-aos="fade-right" data-aos-duration="1300" data-aos-delay="600" id="sub-title">
          Σκάναρε το QR code, δες το μενού και παράγγειλε σε λιγότερο από 1' με
          το κινητό σου
        </h4>
        <div
          data-aos="fade-right"
          data-aos-duration="1300"
          data-aos-delay="800"
          data-aos-anchor="#main-title"
          class="flex-container"
        >
          <img
            src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/qr-codes/demo_qr.png"
            id="demo-qr-img"
          />
          <div class="buttons-container">
            <Button
              @click="scrollTo('contact-us', 'start')"
              :loading="false"
              hoverColor="#db6f6e"
              id="action-button"
            >Επικοινωνία</Button>
            <Button
              v-if="$screen.width > 570"
              @click="openDemo"
              :loading="false"
              hoverColor="#db6f6e"
              id="demo-button"
            >Demo</Button>
            <Button
              v-else
              @click="scrollTo('go-here-on-click-demo-at-nav', 'start')"
              :loading="false"
              hoverColor="#db6f6e"
              id="demo-button"
            >Demo</Button>
          </div>
        </div>
      </div>
      <div id="image">
        <img
          src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/hand-qr.svg"
          alt="app"
          id="hand-qr-image"
          data-aos="fade-left"
          data-aos-duration="1300"
          data-aos-delay="500"
          data-aos-anchor="#main-title"
        />
      </div>
    </div>

    <section id="customers-section">
      <div id="partners">
        <h2 class="section-title" style="color: #003340">Μας εμπιστεύονται</h2>
        <div
          data-aos-easing="ease-in-out"
          data-aos="fade-up"
          data-aos-duration="270"
          data-aos-delay="0"
          data-aos-offset="-70"
          data-aos-anchor="#partners"
          data-aos-anchor-placement="top-center"
        >
          <carousel
            id="carousel-container"
            :perPage="3"
            :autoplay="false"
            :speed="carouselSpeed"
            paginationColor="#BABABA"
            paginationActiveColor="#198b4a"
            easing="ease-in-out"
          >
            <Slide>
              <CarouselSlide name="Coco-mat Athens bc" image="logo_cocomat.jpg"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="President Hotel Athens" image="president_black_logo.jpg"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="The Athens Gate Hotel" image="athensgate_logo.png"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Oasis Hotel Apartments" image="oasis_hotel_logo.png"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Voya Sifnos" image="voya_bw_logo_nw_smaller.png"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Absolute Paradise Santorini" image="absolute-logo.png"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Coco-mat Hotel Athens" image="cocomat_athens_hotel.jpg"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Hawas Beach Hall" image="hawas-only-logo-rszd-4.png"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Captain Vasilis hotel" image="bill_logo.jpg"></CarouselSlide>
            </Slide>
            <!-- <Slide>
              <CarouselSlide name="Nobell 2" image="nobell2_logo.png"></CarouselSlide>
            </Slide>-->
            <Slide>
              <CarouselSlide name="Τζώννυ Ντέπυ" image="tzwnnu_ntepu_logo.jpg"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Coco-mat Athens Jumelle" image="cocomat_jumelle_logo.jpg"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Souvlaki Point" image="souvlaki-point-logo.jpg"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Coco-mat Hotel Nafsika" image="cocomat_hotel_nafsika.png"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Daily Dose Arkalochori" image="daily-dose-logo.jpeg"></CarouselSlide>
            </Slide>
            <Slide>
              <CarouselSlide name="Pony Park" image="pony-park-logo.jpg"></CarouselSlide>
            </Slide>
            <!-- <Slide>
              <CarouselSlide name="146 Suerte St." image="suerte_logo.jpg"></CarouselSlide>
            </Slide>-->
            <!-- <Slide>
              <CarouselSlide name="Nobell 101" image="nobell_logo.png"></CarouselSlide>
            </Slide>-->
            <!-- <Slide>
              <CarouselSlide name="Sky Cafe" image="sky_cafe_logo.png"></CarouselSlide>
            </Slide>-->
            <!-- <Slide>
              <CarouselSlide name="Aurora" image="aurora-logo.jpg"></CarouselSlide>
            </Slide>-->
            <Slide>
              <CarouselSlide name="Maamaya" image="mamaya.jpg"></CarouselSlide>
            </Slide>
          </carousel>
        </div>
      </div>
    </section>

    <section id="how-it-works-section">
      <div id="how-it-works">
        <h2 id="how-it-works-title" class="section-title">Πώς λειτουργεί</h2>
        <div class="step">
          <div id="step-right1">
            <img
              class="step-image"
              src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/steps/step1.svg"
              alt
            />
          </div>
          <div id="step1-left" class="step-info">
            <div class="step-grid">
              <div class="bullet">
                <font-awesome-icon class="bullet-icon" icon="circle" />
              </div>
              <h2 class="step-title">Βήμα 1</h2>
              <h5 class="step-desc">Ο πελάτης σκανάρει το QR Code με την κάμερα του κινητού του</h5>
            </div>
          </div>
        </div>
        <div class="step">
          <div id="step-right2" class="step-info">
            <div class="step-grid">
              <div class="bullet">
                <font-awesome-icon class="bullet-icon" icon="circle" />
              </div>
              <h2 class="step-title">Βήμα 2</h2>
              <h5 v-if="$screen.width > 479" class="step-desc">
                Διαλέγει ό,τι θέλει
                <br />με 1 μόνο κλικ
              </h5>
              <h5 v-else class="step-desc">Διαλέγει ό,τι θέλει με 1 μόνο κλικ</h5>
            </div>
          </div>
          <div id="step2-left">
            <img
              class="step-image"
              src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/steps/step2.svg"
              alt
            />
          </div>
        </div>
        <div style="margin-bottom: -20px" class="step">
          <div id="step-right3">
            <img
              class="step-image"
              src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/steps/step3.svg"
              alt
            />
          </div>
          <div id="step3-left" class="step-info">
            <div class="step-grid">
              <div class="bullet">
                <font-awesome-icon class="bullet-icon" icon="circle" />
              </div>
              <h2 class="step-title">Βήμα 3</h2>
              <h5 class="step-desc">
                Αποστολή παραγγελίας.
                <br />Το κατάστημα ειδοποιείται για την εισερχόμενη
                παραγγελία στην διαχειριστική πλατφόρμα που παρέχουμε
              </h5>
            </div>
          </div>
        </div>
      </div>

      <h2
        id="hotels-section-title"
        class="section-title"
      >Ιδανικό για μεγάλα καταστήματα και ξενοδοχεία</h2>
      <div id="room-service-container">
        <img id="burger-icon" src="../../assets/icon-burger.svg" alt />
        <img id="blob2-image" src="../../assets/landing-page/blob2.svg" alt />

        <div id="room-service">
          <div>
            <h4 class="perfect-room-service-choice">
              <span style="color: #198b4a">Ανώτερη</span> εμπειρία φιλοξενίας
            </h4>
            <p class="perfect-choice-description">
              Αναβαθμίστε ψηφιακά το ξενοδοχείο και δώστε στoυς πελάτες σας
              την δυνατότητα να δουν και να χρησιμοποιήσουν όλες τις υπηρεσίες σας
              από την άνεση του κινητού τους,
              <strong>χωρίς να χρειαστεί να κατεβάσουν κάποια εφαρμογή.</strong>
            </p>
            <div class="services-buttons">
              <img
                src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/qr-codes/demo_qr_services.png"
                id="hotel-demo-qr-img"
              />
              <Button
                @click="openDepartmentsDemo"
                :loading="false"
                hoverColor="#db6f6e"
                id="hotel-demo-btn"
                class="services-demo-button"
                style="margin-right: 20px"
              >Demo Hotel</Button>
            </div>
          </div>
          <img
            id="fluter-services-image"
            src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/screenshots/hotel-services.png"
            alt
          />
        </div>
      </div>
      <div id="hard-places">
        <img id="coffee-icon" src="../../assets/icon-coffee.svg" alt />
        <img id="blob-image" src="../../assets/landing-page/blob (1).svg" alt />
        <div id="order-from-anywhere">
          <img
            id="menu-fluter-image"
            src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/screenshots/fnb-menu-compressed.png"
            alt
          />
          <h4 class="perfect-choice-explanation">
            <span style="color: #198b4a">Εύκολη</span> παραγγελία στα μέρη που
            <br />
            <span style="color: #f30">δυσκολεύουν</span>
            το προσωπικό όπως παραλία, ξαπλώστρες και όροφοι.
          </h4>
        </div>
      </div>

      <div id="multiple-services-container">
        <img id="avocado-icon" src="../../assets/icon-avocado-bread.svg" alt />
        <img id="blob3-image" src="../../assets/landing-page/blob6.svg" alt />
        <div id="multiple-services">
          <div>
            <h4 id="multiple-services-title" class="perfect-room-service-choice">
              Διαχωρισμός του καταστήματος σε
              <span style="color: #198b4a">διαφορετικές υπηρεσίες</span>
            </h4>
            <p id="multiple-services-description">
              Το κατάστημα χωρίζεται τμηματικά (π.χ. εστιατόριο,
              gift-shop, reception κλπ). Ο χρήστης σκανάροντας μπορεί να δει και να παραγγείλει από όλες τις υπηρεσίες που εξυπηρετούν το σημείο που βρίσκεται.
            </p>
            <div class="services-buttons">
              <img
                src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/qr-codes/demo_qr_services.png"
                id="services-demo-qr-img"
              />
              <Button
                v-if="$screen.width < 800 && $screen.width > 570"
                @click="openDepartmentsDemo"
                :loading="false"
                hoverColor="#db6f6e"
                id="go-to-services-btn"
                class="services-demo-button"
              >Δείτε ένα demo εδώ</Button>
            </div>
          </div>
          <img
            id="fluter-cart-image"
            src="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/screenshots/fluter-cart.png"
            alt
          />
        </div>
        <div id="go-here-on-click-demo-at-nav"></div>
      </div>
    </section>

    <section id="fluter-demo-section">
      <div id="fluter-demo-container">
        <div>
          <h6 id="navigate-live-title">Δοκιμάστε ένα από τα παρακάτω demo</h6>
        </div>

        <div id="mobile-frames-container" v-if="$screen.width > 570">
          <div class="frame-container" id="hotel-frame-container">
            <h6 class="mobile-frame-title">
              <font-awesome-icon style="margin-right: 10px;" icon="hotel" />Ξενοδοχείο
            </h6>
            <iframe
              class="mobile-view-frame"
              src="https://www.fluter.gr/menu/253"
              title="Hotel fluter iframe"
            ></iframe>
          </div>

          <div class="frame-container" id="cafe-frame-container">
            <h6 class="mobile-frame-title">
              <font-awesome-icon style="margin-right: 10px;" icon="coffee" />Cafe
            </h6>
            <iframe
              class="mobile-view-frame"
              src="https://www.fluter.gr/menu/186"
              title="Cafe fluter iframe"
            ></iframe>
          </div>
        </div>

        <div id="demo-buttons-container" v-else>
          <Button
            @click="openDepartmentsDemo"
            :loading="false"
            hoverColor="#db6f6e"
            id="hotel-demo-button"
            style="width: 90vw;"
          >
            <font-awesome-icon style="margin-right: 9px;" icon="hotel" />Δείτε ένα demo ξενοδοχείο
          </Button>

          <Button
            @click="openDemo"
            :loading="false"
            hoverColor="#db6f6e"
            id="cafe-demo-button"
            style="width: 90vw;"
          >
            <font-awesome-icon style="margin-right: 9px;" icon="coffee" />Δείτε ένα demo cafe
          </Button>
        </div>
      </div>
    </section>

    <section id="fluter-benefits">
      <div id="why-fluter">
        <h2 id="why-fluter-title" class="section-title">Γιατί να διαλέξετε το Fluter</h2>
        <ul>
          <li>
            <!-- <div
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="550"
            data-aos-anchor="#bubble1"
            data-aos-anchor-placement="top-center"
            data-aos-delay="100"
            data-aos-offset="-10"
            class="perk-container"
            >-->
            <div class="perk-container">
              <div id="bubble4" class="bubble">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
              <h4 class="bubble-title">Αύξηση εσόδων</h4>
              <h4
                class="bubble-subtitle"
              >Καθιστώντας εύκολο και βολικό στους πελάτες σας να παραγγείλουν από το κινητό τους, είναι πιο πιθανό να κάνουν επιπλέον παραγγελίες και να ξοδέψουν περισσότερα χρήματα.</h4>
            </div>
          </li>
          <li>
            <!-- <div
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="550"
            data-aos-anchor="#bubble1"
            data-aos-anchor-placement="top-center"
            data-aos-delay="100"
            data-aos-offset="-10"
            class="perk-container"
            >-->
            <div class="perk-container">
              <div id="bubble2" class="bubble">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 448 512">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
                  />
                </svg>
              </div>
              <h4 class="bubble-title">Αντιμετώπιση έλλειψης προσωπικού</h4>
              <h4
                class="bubble-subtitle"
              >Το fluter εξαλείφει την ανάγκη για επιπλέον προσλήψεις στο service, επιτρέποντας στο υπάρχον προσωπικό σας να επικεντρωθεί σε άλλες βασικές εργασίες.</h4>
            </div>
          </li>
          <li>
            <!-- <div
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="550"
            data-aos-anchor="#bubble1"
            data-aos-anchor-placement="top-center"
            data-aos-delay="300"
            data-aos-offset="-10"
            class="perk-container"
            >-->
            <div class="perk-container">
              <div id="bubble3" class="bubble">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h4 class="bubble-title">Ενισχύση του service</h4>
              <h4 class="bubble-subtitle">
                Αυξήστε την αποτελεσματικότητα του service
                και εξαλείψτε την αργοπορημένη εξυπηρέτηση
                καθώς και τα λάθη στις παραγγελίες.
              </h4>
            </div>
          </li>
          <li>
            <!-- <div
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="550"
            data-aos-anchor="#bubble1"
            data-aos-anchor-placement="top-center"
            data-aos-delay="500"
            data-aos-offset="-10"
            class="perk-container"
            >-->
            <div class="perk-container">
              <div id="bubble1" class="bubble">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 384 512">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M320 0H64C37.5 0 16 21.5 16 48v416C16 490.5 37.5 512 64 512h256c26.5 0 48-21.5 48-48v-416C368 21.5 346.5 0 320 0zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1zM304 384h-224V64h224V384z"
                  />
                </svg>
              </div>
              <h4 class="bubble-title">Δε χρείαζεται λήψη εφαρμογής</h4>
              <h4 class="bubble-subtitle">
                Ο πελάτης δεν θέλει να κατεβάσει κάποιο app, θέλει απλά να
                παραγγείλει. Σκανάροντας το QR code του fluter ανακατευθύνεται
                αυτόματα στο web διαδραστικό μενού σας.
              </h4>
            </div>
          </li>
          <li>
            <!-- <div
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="550"
            data-aos-anchor="#bubble1"
            data-aos-anchor-placement="top-center"
            data-aos-delay="300"
            data-aos-offset="-10"
            class="perk-container"
            >-->
            <div class="perk-container">
              <div id="bubble5" class="bubble">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <h4 class="bubble-title">Ιστορικό παραγγελιών</h4>
              <h4 class="bubble-subtitle">
                Όλες οι παραγγελίες είναι προσβάσιμες και εύκολα διαχειρίσιμες
                μέσα από την εφαρμογή που παρέχουμε στο κατάστημα.
              </h4>
            </div>
          </li>
          <li>
            <!-- <div
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-duration="550"
            data-aos-anchor="#bubble1"
            data-aos-anchor-placement="top-center"
            data-aos-delay="200"
            data-aos-offset="-10"
            class="perk-container"
            >-->
            <div class="perk-container">
              <div id="bubble6" class="bubble">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                  />
                </svg>
              </div>
              <h4 class="bubble-title">Eκτύπωση προϊόντων ανά πόστο</h4>
              <h4
                class="bubble-subtitle"
              >Τα προϊόντα κάθε παραγγελίας εκτυπώνονται ανεξάρτητα μεταξύ τους στα σημεία της επιλογής σας.</h4>
            </div>
          </li>
        </ul>
        <!-- <img
          id="wave"
          style="transform: translateY(10px)"
          src="../../assets/landing-page/wave2.svg"
          alt
        />-->
      </div>
    </section>

    <div class="header-shape-1"></div>
    <div class="header-shape-2">
      <img src="../../assets/images/header-shape-2.svg" alt="shape" />
    </div>

    <section id="contact-us-section">
      <div id="contact-us">
        <div id="contact-us-text">
          <h4
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="650"
            data-aos-delay="200"
            :data-aos-once="true"
          >
            <br />Στείλτε μας μήνυμα και θα επικοινωνήσουμε μαζί σας
          </h4>
        </div>
        <div id="contact-us-form">
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="650"
            data-aos-delay="200"
            :data-aos-once="true"
            id="form-container"
          >
            <div class="input-container">
              <h4 id="contact-form-fluter-phone">
                <a
                  style="color: #333 !important; text-decoration: none !important"
                  href="tel:+302111187687"
                >
                  Fluter τηλ:
                  <span
                    style="text-decoration: underline; font-weight: 800; color:#088c47; margin-left:4px;"
                  >2111187687</span>
                </a>
              </h4>
            </div>
            <div class="input-container">
              <h4>Όνομα</h4>
              <input
                @focus="onFocus"
                @blur="onBlur"
                v-model="name"
                type="text"
                name="name"
                id="name"
              />
            </div>
            <div class="input-container">
              <h4>Τηλέφωνο</h4>
              <input
                @focus="onFocus"
                @blur="onBlur"
                v-model="phone"
                type="tel"
                name="phone"
                id="phone-input"
              />
            </div>
            <div class="input-container">
              <h4>Email</h4>
              <input
                @focus="onFocus"
                @blur="onBlur"
                v-model="email"
                type="email"
                name="email"
                id="email"
              />
            </div>
            <div id="text-area-container" class="input-container">
              <h4>Μήνυμα</h4>
              <textarea
                @focus="onFocus"
                @blur="onBlur"
                v-model="message"
                name="message"
                id="message"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <p v-if="formSuccess == false" class="error">{{ errorMessage }}</p>
            <p v-if="formSuccess" class="success">{{ successfullMessage }}</p>
            <Button :loading="buttonLoading" @click="submit" id="send-button">Αποστολή</Button>

            <!-- <div :class="{ shape: true, shape1: true, formShape1: true }"></div>
          <div :class="{ shape: true, shape2: true, formShape2: true }"></div>
          <div :class="{ shape: true, shape3: true, formShape3: true }"></div>
          <div :class="{ shape: true, shape6: true, formShape6: true }"></div>
          <div :class="{ shape: true, shape4: true, formShape4: true }"></div>
            <div :class="{ shape: true, shape5: true, formShape5: true }"></div>-->
          </div>
        </div>
      </div>
    </section>

    <div @mousemove="updateCoordinatesFooter" id="footer">
      <div id="footer-logo-container">
        <h4 id="footer-logo">
          <img src="../../assets/Full_logo.svg" alt />
        </h4>
        <!-- <h4 id="footer-description">
          Fluter (Ουσιαστικό): Σημαίνει παραγγελία στη γλώσσα της εστίασης.
          <br />Τι κοινό έχουν το τραπέζι του ψητοπωλείου, το δωμάτιο του ξενοδοχείου
          και η ξαπλώστρα του καφέ της παραλίας; Σε όλα παραγγέλνεις πιο
          ευχάριστα και γρήγορα από ποτέ με το Fluter.
        </h4>-->
        <h4
          id="footer-description"
        >Σκάναρε το QR code, δες το μενού και παράγγειλε σε λιγότερο από 1' με το κινητό σου</h4>
      </div>
      <div id="footer-links">
        <div id="links1">
          <h4 id="useful-links">Χρήσιμοι σύνδεσμοι</h4>
          <div id="links">
            <div id="link-column1">
              <h4 @click="goToInfo('terms-of-service')">Όροι χρήσης</h4>
              <h4 @click="goToInfo('contact-us')">Επικοινωνία</h4>
              <h4 @click="goToInfo('privacy-policy')">Πολιτική απορρήτου</h4>
            </div>
            <div id="link-column2">
              <h4 @click="goToInfo('rating-policy')">Πολιτική αξιολογήσεων</h4>
              <h4 @click="goToInfo('cookies-policy')">Πολιτική cookies</h4>
            </div>
          </div>
        </div>
        <div id="links2">
          <h4 id="useful-links">Πληροφορίες</h4>
          <div id="links">
            <div id="link-column3">
              <div id="phone">
                <font-awesome-icon class="icon" icon="mobile-alt" />
                <h4>2111187687</h4>
              </div>
              <div id="contact-email">
                <font-awesome-icon class="icon" :icon="['far', 'paper-plane']" />
                <h4>info@fluter.gr</h4>
              </div>
              <div id="facebook">
                <img src="../../assets/landing-page/facebook_icon.png" alt="facebook icon" />
                <a href="https://www.facebook.com/flutergreece" target="_blank">facebook</a>
              </div>
              <div id="instagram">
                <img src="../../assets/landing-page/instagram_logo.png" alt="instagram icon" />
                <a href="https://www.instagram.com/fluter.gr" target="_blank">instagram</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-2"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-3"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-4"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-5"
      ></div>
      <div
        :style="{
          transform:
            'translate(' +
            this.footerX +
            'px,' +
            this.footerY +
            'px) !important',
        }"
        class="footer-shape shape-6"
      ></div>
    </div>
    <div class="footer-payments-row">
      <div class="footer-payments-container">
        <div class="footer-payments-row-inner">
          <div class="logo-everypay"></div>
          <div class="logo-visa"></div>
          <div class="logo-mastercard"></div>
          <div class="logo-maestro"></div>
          <div class="logo-ticket-restaurant"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../Button";
import MessageApi from "../../api/message";
import ProfileDropdown from "../ProfileDropdown";
import CarouselSlide from "../CarouselSlide.vue";
import { Carousel, Slide } from "vue-carousel";
import LogApi from "../../api/log";

export default {
  name: "Landing",
  components: {
    Button,
    ProfileDropdown,
    Carousel,
    CarouselSlide,
    Slide,
  },
  beforeRouteEnter(to, from, next) {
    if (from.name) {
      next();
      location.reload();
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEntranceOpen) {
      this.closeEntrance();
      this.$router.push({
        name: "landing",
      });
      next(false);
    } else {
      next();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.sections = document.querySelectorAll("section");
    this.navElements = document.querySelectorAll(".nav-item");
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("scroll", this.isSectionActive);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("scroll", this.isSectionActive);
  },
  computed: {
    name: {
      set(newName) {
        this.clientName = newName;
      },

      get() {
        return this.clientName;
      },
    },

    phone: {
      set(newPhone) {
        this.clientPhone = newPhone;
      },

      get() {
        return this.clientPhone;
      },
    },
    email: {
      set(newEmail) {
        this.clientEmail = newEmail;
      },

      get() {
        return this.clientEmail;
      },
    },

    message: {
      set(newMessage) {
        this.clientMessage = newMessage;
      },

      get() {
        return this.clientMessage;
      },
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    carouselSpeed() {
      return this.$screen.width > 800 ? 1000 : 650;
    },
  },
  data() {
    return {
      isOnTop: true,
      isInitLoad: true,
      justLoaded: true,
      buttonHovering: false,
      x: 0,
      y: 0,
      footerX: 0,
      footerY: 0,
      cards: {
        1: {
          cardYRotation: 0,
          cardXRotation: 0,
        },

        2: {
          cardYRotation: 0,
          cardXRotation: 0,
        },

        3: {
          cardYRotation: 0,
          cardXRotation: 0,
        },
      },
      formSuccess: undefined,
      errorMessage: "",
      successfullMessage: "",
      buttonLoading: false,
      clientName: "",
      clientPhone: "",
      clientEmail: "",
      clientMessage: "",
      areOptionsOpen: false,
      isProfileDropdownOpen: false,
      sections: [],
      navElements: []
    };
  },
  methods: {
    openDemo: function () {
      window.open("https://www.fluter.gr/menu/186", "_blank").focus();
    },

    openDepartmentsDemo: function () {
      window.open("https://www.fluter.gr/menu/253", "_blank").focus();
    },

    onScroll: function (e) {
      if (this.isInitLoad) {
        this.isInitLoad = false;
      }

      this.isOnTop = window.top.scrollY == 0;
    },

    updateCoordinates: function (event) {
      let midHeight = this.$screen.height / 2;
      let midWidth = this.$screen.width / 2;
      let heightPercentage = (event.screenY / midHeight) * 100 - 100;
      let widthPercentage = (event.screenX / midWidth) * 100 - 100;

      this.x = widthPercentage * 0.5;
      this.y = heightPercentage * 0.5;
    },

    updateCoordinatesFooter: function (event) {
      let midHeight = this.$screen.height / 2;
      let midWidth = this.$screen.width / 2;
      let heightPercentage = (event.screenY / midHeight) * 100 - 100;
      let widthPercentage = (event.screenX / midWidth) * 100 - 100;

      this.footerX = widthPercentage * 0.5;
      this.footerY = heightPercentage * 0.5;
    },

    updateRotation: function (event, cardNumber) {
      /* Store the x position */
      const xVal = event.layerX;
      /* Store the y position */
      const yVal = event.layerY;

      let ref = `card${cardNumber}`;
      let width = this.$refs[ref].clientWidth;
      let height = this.$refs[ref].clientHeight;

      /*
       * Calculate rotation valuee along the Y-axis
       * Here the multiplier 20 is to
       * Control the rotation
       * You can change the value and see the results
       */
      this.cards[cardNumber].cardYRotation = 20 * ((xVal - width / 2) / width);

      /* Calculate the rotation along the X-axis */
      this.cards[cardNumber].cardXRotation =
        -20 * ((yVal - height / 2) / height);
    },

    resetRotation: function (cardNumber) {
      this.cards[cardNumber].cardYRotation = 0;
      this.cards[cardNumber].cardXRotation = 0;
    },

    submit: async function () {
      this.buttonLoading = true;
      if (this.hasEmptyInputs()) {
        this.formSuccess = false;
        this.errorMessage = "Όλα τα πεδία είναι υποχρεωτικά";
        this.buttonLoading = false;
        return;
      }

      if (this.hasInvalidEmail()) {
        this.formSuccess = false;
        this.errorMessage =
          "Το email πρέπει να είναι του τύπου someone@example.com";
        this.buttonLoading = false;
        return;
      }

      let messageData = {
        name: this.clientName,
        phone: this.clientPhone,
        email: this.clientEmail,
        message: this.clientMessage,
      };

      let result = await MessageApi.postMessage(messageData);

      if (result.success) {
        this.formSuccess = true;
        this.successfullMessage = "Το μήνυμα στάλθηκε με επιτυχία";
      } else {
        this.errorInSendMessage = true;
        this.formSuccess = false;
        this.sendMessageError = result.message;
        this.errorMessage = result.message.GRE;
      }

      this.buttonLoading = false;
    },

    hasEmptyInputs: function () {
      let emptyEmail = this.clientEmail.replace(/\s+/g, "") === "";
      let emptyPhone = this.clientPhone.replace(/\s+/g, "") === "";
      let emptyName = this.clientName.replace(/\s+/g, "") === "";
      let emptyMessage = this.clientMessage.replace(/\s+/g, "") === "";

      return emptyEmail || emptyPhone || emptyName || emptyMessage;
    },

    hasInvalidEmail: function () {
      let emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let isValidEmail = emailRegex.test(
        String(this.clientEmail).toLowerCase()
      );

      return !isValidEmail;
    },

    shouldSendMessage: function () {
      return !this.badEmail && !this.emptyInputs;
    },

    toggleOptions: function () {
      this.areOptionsOpen = !this.areOptionsOpen;
    },

    toggleProfileDropdown: function () {
      this.isProfileDropdownOpen = !this.isProfileDropdownOpen
    },

    scrollTo: function (elementId, block) {
      let element = document.getElementById(elementId);
      element.scrollIntoView({
        behavior: "smooth",
        block: block,
        inline: "nearest",
      });
      if (this.$screen.width <= 800) {
        this.areOptionsOpen = false;
      }
    },

    goToInfo: function (type) {
      this.$router.push({ name: "specific-info", params: { type: type } });
    },

    onFocus: function (event) {
      if (this.$screen.width <= 550) {
        setTimeout(() => {
          var element = document.getElementById("contact-us-form");

          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 100);
      }
    },

    onBlur: function (event) {
      if (this.$screen.width <= 550) {
        setTimeout(() => {
          var element = document.getElementById("contact-us-form");

          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 75);
      }
    },

    isSectionActive: function () {
      try {
        let current = "";

        this.sections.forEach((section) => {
          const sectionTop = section.offsetTop;
          if (scrollY >= sectionTop - 280) {
            current = section.getAttribute("id");
          }
        });

        this.navElements.forEach((navElement) => {
          navElement.classList.remove("active-nav-element");
          if (navElement.classList.contains(current)) {
            navElement.classList.add("active-nav-element");
          }
        });
      } catch (error) {
        console.log("Could not activate scroll event", error)
      }
    },

    initiateEntrance: function () {
      LogApi.enterFluterClicked();
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    onMaskClick: function () {
      if (this.isEntranceOpen) {
        this.closeEntrance();
      }
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },
  },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:300,400,500,600,700&display=swap"); */

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
  transition: 0.7s;
}

#profile-dropdown {
  position: absolute;
  transform: translate(-125%, -8%);
  margin-left: 70px;
}

#mobile-profile-dropdown {
  position: absolute;
  transform: translate(-125%, -8%);
  margin-left: 10px;
}

#landing-page {
  background: white;
  font-family: inter;
  font-weight: normal;
  font-style: normal;
  color: #747e88;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#start-screen {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  z-index: 2;
}

#copyright,
#image {
  width: 50%;
  height: 100%;
}

#fluter-demo-container {
  margin-top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#mobile-frames-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#demo-buttons-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.frame-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#hotel-frame-container {
  margin-right: 120px;
}

.mobile-view-frame {
  width: 400px;
  height: 83vh;
  min-height: 680px;
  max-height: 830px;
  border-radius: 22px;
  border: solid #003340;
  border-width: 55px 7px 30px 7px;
}

#navigate-live-title {
  margin: 0vh 0 2vh 0;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  color: #38424d;
}

.mobile-frame-title {
  font-size: 30px;
  font-weight: 700;
  color: #198b4a;
  margin-bottom: 40px;
}

#image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  #image {
    align-items: flex-start;
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 950px) {
  #mobile-frames-container {
    display: flex;
    flex-direction: column !important;
  }

  #hotel-frame-container {
    margin-right: 0px !important;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 500px) {
  .mobile-view-frame {
    width: 86vw;
    height: 84vh;
  }
}

@media screen and (max-width: 550px) {
  #hotels-section-title {
    margin-top: 15vh !important;
  }
}

#copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#main-title {
  font-size: 55px;
  margin: 220px 0 10px 9.5vw;
  color: #38424d;
  font-weight: 800;
  font-family: Commissioner;
}
@media only screen and (min-width: 1800px) {
  #main-title {
    font-size: 74px;
    margin-top: 310px;
    margin-bottom: 24px;
    margin-left: 8.5vw;
  }
}
@media only screen and (max-width: 1370px) {
  #main-title {
    font-size: 50px;
    margin-left: 8vw;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #main-title {
    font-size: 47px;
    margin: 160px 0 20px 7.5vw;
  }
}
@media only screen and (max-width: 1050px) and (max-height: 600px) {
  #main-title {
    font-size: 40px;
    margin: 160px 0 20px 7vw;
  }
}
@media (max-width: 992px) {
  #main-title {
    font-size: 37px;
    /* margin: 160px 0 35px 10vw; */
  }
}

@media (max-width: 799px) {
  #demo-qr-img {
    display: none;
  }

  #services-demo-qr-img {
    display: none;
  }

  #hotel-demo-qr-img {
    display: none;
  }

  .buttons-container {
    display: flex;
  }
}

@media (min-width: 800px) {
  #demo-button {
    display: none;
  }

  #hotel-demo-btn {
    display: none;
  }

  .flex-container {
    min-width: 600px;
  }
}

@media (max-width: 767px) {
  #main-title {
    font-size: 40px;
    margin: 160px 20px 20px 8vw !important;
  }
}
@media screen and (max-width: 550px) and (max-height: 680px) {
  #main-title {
    margin-top: 150px !important;
  }
}
@media (max-width: 360px) {
  #main-title {
    font-size: 33px;
    margin-top: 140px !important;
  }
}

#sub-title {
  color: #747e88;
  color: cadetblue;
  color: rgb(120, 119, 119);
  font-size: 24px;
  margin-left: 10vw;
  font-weight: 700;
  margin-bottom: 40px;
}
@media only screen and (min-width: 1800px) {
  #sub-title {
    font-size: 31px;
    margin-left: 8.8vw;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1370px) {
  #sub-title {
    font-size: 22px;
    margin-left: 8.5vw;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #sub-title {
    font-size: 20px;
    margin-bottom: 35px;
    margin-left: 8vw;
  }
}
@media only screen and (max-width: 1050px) and (max-height: 600px) {
  #sub-title {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 7.5vw;
  }
}
@media (max-width: 992px) {
  #sub-title {
    font-size: 18px;
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  #sub-title {
    margin-right: 20px;
    margin-bottom: 30px;
    margin-left: 8.5vw;
  }
}
@media (max-width: 360px) {
  #sub-title {
    font-size: 14px;
  }
}

.flex-container {
  display: flex;
  z-index: 4;
  margin-bottom: 35px;
}

.services-buttons {
  display: flex;
  z-index: 4;
}

#action-button,
#demo-button {
  background: #03c463;
  /* background: linear-gradient(62deg, #198b4a, #04da6f); */
  /* background: linear-gradient(to bottom right, #5cb85c, #04da6f 67%); */
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.8rem 1.75rem;
  max-height: 50px;
}

#hotel-demo-button {
  background: linear-gradient(62deg, #198b4a, #04da6f);
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.8rem 1.75rem;
  max-height: 50px;
  margin-bottom: 30px;
}

#cafe-demo-button {
  background: #fff;
  color: #198b4a;
  border: 1px solid #03c463;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.8rem 1.75rem;
  max-height: 50px;
}

.services-demo-button {
  background: #03c463;
  /* background: linear-gradient(62deg, #198b4a, #04da6f); */
  /* background: linear-gradient(to bottom right, #5cb85c, #04da6f 67%); */
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.8rem 1.75rem;
  max-height: 50px;
}

#action-button {
  margin-left: 2vw;
  min-width: 24vh;
}

.services-demo-button {
  min-width: 20vh;
}

@media screen and (max-width: 800px) {
  .services-demo-button {
    margin-bottom: 10vh;
  }
}
@media screen and (max-width: 380px) and (max-height: 750px) {
  .services-demo-button {
    margin-bottom: 14vh;
  }
}

#demo-button {
  background: #fff;
  color: #03c463;
  border: 1px solid #03c463;
  min-width: 24vh;
  margin-left: 2vw;
}

@media (max-width: 570px) {
  #demo-button {
    min-width: 14vh;
  }

  #action-button {
    min-width: 18vh;
  }
}

#demo-qr-img {
  max-width: 20%;
  height: auto;
  margin-left: 9vw;
}

#services-demo-qr-img {
  width: 160px;
  height: auto;
  margin-right: 3vh;
}

#hotel-demo-qr-img {
  width: 160px;
  height: auto;
  margin-right: 3vh;
}

@media (max-width: 1800px) {
  #demo-qr-img {
    margin-left: 9.8vw;
  }
}

@media (max-width: 1370px) {
  #demo-qr-img {
    margin-left: 8.8vw;
  }
}

@media (max-width: 1200px) {
  #demo-qr-img {
    margin-left: 8.2vw;
  }
}

@media (max-width: 1400px) {
  #services-demo-qr-img {
    height: auto;
    margin-right: 3vh;
  }

  #hotel-demo-qr-img {
    height: auto;
    margin-right: 3vh;
  }
}

.services-demo-button {
  margin-left: 0;
}

@media only screen and (min-width: 1800px) {
  #action-button,
  #demo-button {
    font-size: 20px;
    margin-left: 4.5vw;
  }
}
@media (min-width: 992px) and (max-width: 1370px) {
  #action-button,
  #demo-button {
    margin-left: 5vw;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #action-button,
  #demo-button {
    margin-left: 5vw;
  }
}
@media only screen and (max-width: 1050px) and (max-height: 600px) {
  #action-button,
  #demo-button {
    margin-left: 7vw;
  }
}
@media (max-width: 992px) {
  #action-button,
  #demo-button {
    margin-left: 8.5vw;
  }
}
@media (max-width: 767px) {
  #action-button,
  #demo-button {
    margin-left: 8vw;
  }
}
@media (max-width: 380px) {
  #action-button,
  #demo-button {
    padding: 12px 18px;
    font-size: 14px;
  }
}

#hand-qr-image {
  width: 58%;
  height: auto;
  margin: 165px 0 40px -1vw;
}
@media screen and (min-width: 1800px) {
  #hand-qr-image {
    margin-top: 245px;
    margin-left: 4vw;
  }
}
@media screen and (max-width: 1370px) {
  #hand-qr-image {
    margin-top: 175px;
    margin-left: 2vw;
    width: 63%;
  }
}
@media only screen and (max-width: 1050px) and (max-height: 600px) {
  #hand-qr-image {
    margin-top: 170px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #hand-qr-image {
    margin-top: 120px;
    margin-left: 9vw;
  }
}
@media screen and (max-width: 800px) {
  #hand-qr-image {
    margin: 35px 0 40px 7vw;
    width: 75%;
  }
}

@media screen and (max-width: 370px) {
  #hand-qr-image {
    margin: 30px 0 40px 9vw;
  }
}

#navbar {
  position: fixed;
  width: 100vw;
  display: flex;
  height: 25vh;
  align-items: center;
  justify-content: space-between;
  padding: 0 7.5vw 0 7vw;
  background: transparent;
  box-shadow: none;
  z-index: 3;
}

.visibleNavbar {
  animation: addBoxShadow 0.4s forwards ease-in-out;
}

@keyframes addBoxShadow {
  from {
    background: transparent;
    box-shadow: none;
    height: 25vh;
  }

  to {
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    height: 12vh;
  }
}

.invisibleNavbar {
  animation: removeBoxShadow 0.4s forwards ease-in-out;
}

@keyframes removeBoxShadow {
  to {
    background: transparent;
    box-shadow: none;
    height: 25vh;
  }

  from {
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    height: 15vh;
  }
}

#navigation {
  display: flex;
  position: relative;
}

.nav-item {
  margin-left: 7.5vw;
  font-size: 16px;
  font-weight: 700;
  color: #38424d;
  cursor: pointer;
  white-space: nowrap;
}

#demo-mobile-nav-item {
  font-size: 18px;
  font-weight: 900;
  color: #003340;
  cursor: pointer;
}

.demo-z-indexed {
  z-index: 20;
}

.demo-z-not-indexed {
  z-index: 0 !important;
}

.demo-navbar-logged-in-user {
  margin-right: 100px;
}

.demo-navbar-not-logged-in {
  margin-right: 5vw;
}

.active-nav-element {
  color: #5cb85c !important;
  color: #02bd5f !important;
  font-weight: 900 !important;
}

#mobile-navbar-container {
  display: flex;
  flex-direction: row;
}

#mobile-navigation {
  position: relative;
}

#bars {
  font-size: 25px;
  color: #5cb85c;
  margin-right: 4vw;
}

#navigation-options {
  position: absolute;
  background: white;
  right: 0;
  bottom: 0;
  transform: translate(-30%, 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  z-index: 202;
}

#navigation-options > :last-child {
  border-bottom: none;
}

.option {
  color: gray;
  font-size: 14px;
  padding: 6px 11px 6px 11px;
  border-bottom: 1px solid #5cb85c;
  height: 100%;
  cursor: pointer;
  margin: 0;
}
@media screen and (max-width: 800px) {
  .option {
    padding: 12px 11px 12px 11px;
    font-size: 12px;
  }
}

.option:hover {
  background: #efefef;
}

#logo {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2.5vw;
}
@media screen and (min-width: 1800px) {
  #logo {
    margin-left: 1.5vw;
  }
}
@media only screen and (max-width: 1370px) {
  #logo {
    margin-left: 0.75vw;
  }
}
@media only screen and (max-width: 1160px) {
  #logo {
    margin-left: 0.25vw;
  }
}
@media only screen and (max-width: 1050px) and (max-height: 600px) {
  #logo {
    margin-left: 0vw;
  }
}

#logo > img {
  height: 50%;
  width: auto;
  margin-bottom: 22%;
}
@media screen and (max-width: 800px) {
  #logo > img {
    height: 50%;
    margin-bottom: 18%;
  }
}

.header-shape-1 {
  position: absolute;
  top: 0;
  height: 120%;
  right: 0;
  z-index: 1 !important;
  background-image: url(../../assets/images/header-shape-1.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1 !important;
}

.green {
  color: #5cb85c;
  color: #03c463;
  color: #02bd5f;
  /* color: #04da6f; */
  white-space: nowrap;
}
@media screen and (max-width: 800px) {
  .green {
    white-space: unset;
  }
}

.shape {
  position: absolute;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

.shape1 {
  width: 75px;
  height: 75px;
  background: -webkit-linear-gradient(
    rgba(254, 132, 100, 0.5) 0%,
    rgba(254, 110, 154, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(254, 132, 100, 0.5) 0%,
    rgba(254, 110, 154, 0.5) 100%
  );
  background: linear-gradient(
    rgba(254, 132, 100, 0.5) 0%,
    rgba(254, 110, 154, 0.5) 100%
  );
  border-radius: 50%;
  left: 130px;
  top: 25%;
}
@media only screen and (max-width: 800px) {
  .shape1 {
    top: 15%;
    left: 10px;
  }
}
.shape2 {
  width: 39px;
  height: 39px;
  background: -webkit-linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  background: linear-gradient(
    rgba(51, 200, 193, 0.5) 0%,
    rgba(17, 155, 210, 0.5) 100%
  );
  left: 150px;
  bottom: 40px;
  border-radius: 50%;
}
@media only screen and (max-width: 800px) {
  .shape2 {
    bottom: 35%;
    left: 60px;
  }
}
.shape3 {
  width: 19px;
  height: 19px;
  background: -webkit-linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  background: -o-linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  background: linear-gradient(
    rgba(54, 28, 193, 0.5) 0%,
    rgba(46, 130, 239, 0.5) 100%
  );
  bottom: 25%;
  left: 26%;
  border-radius: 50%;
}
@media only screen and (max-width: 800px) {
  .shape3 {
    bottom: 50%;
    left: 65%;
  }
}
.shape4 {
  background-color: rgba(226, 158, 25, 0.55);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  top: 175px;
  left: 40%;
}
@media only screen and (max-width: 800px) {
  .shape4 {
    top: 250px;
    left: 65%;
  }
}
.shape5 {
  width: 19px;
  height: 19px;
  background-color: rgba(108, 99, 255, 0.55);
  transform: scale(1, 1);
  left: 50%;
  bottom: 20%;
  border-radius: 50%;
}
@media only screen and (max-width: 800px) {
  .shape5 {
    left: 75%;
  }
}
.shape6 {
  width: 14px;
  height: 14px;
  background-color: rgba(235, 163, 26, 0.55);
  border-radius: 50%;
  left: 45%;
  bottom: 70px;
}
@media only screen and (max-width: 800px) {
  .shape6 {
    bottom: 22%;
    left: 5%;
  }
}

#carousel-container {
  width: 70vw;
  margin-left: 15vw;
}
@media screen and (max-width: 800px) {
  #carousel-container {
    width: 95vw;
    margin-left: 2.5vw;
  }
}

#why-fluter {
  position: relative;
  width: 100vw;
  /* background: linear-gradient(to bottom, #f3fdff, #b2ecf8); */
}

.section-title {
  width: 100vw;
  text-align: center;
  margin: 0vh 0 15vh 0;
  font-size: 35px;
  font-weight: 900;
  color: #38424d;
}
@media screen and (min-width: 1800px) {
  .section-title {
    font-size: 42px;
  }

  #navigate-live-title {
    font-size: 42px;
  }
}

@media screen and (max-width: 1010px) {
  #navigate-live-title {
    width: 50vw;
  }
}

@media screen and (max-width: 900px) {
  #navigate-live-title {
    width: 60vw;
  }
}

@media screen and (max-width: 800px) {
  .section-title {
    width: 80vw;
    margin-left: 10vw;
    font-size: 28px;
  }

  #navigate-live-title {
    /* margin: 0vh 6px 2vh 6px; */
    font-size: 28px;
    width: 60vw;
  }
}

@media screen and (max-width: 600px) {
  #navigate-live-title {
    width: 85vw;
  }
}

@media screen and (max-width: 500px) {
  #navigate-live-title {
    width: 90vw;
  }

  .demo-navbar-logged-in-user {
    margin-right: 80px;
    font-size: 16px !important;
  }

  .demo-navbar-not-logged-in {
    font-size: 16px !important;
  }
}

#hard-places,
#room-service-container,
#multiple-services-container {
  position: relative;
}

#hotels-section-title {
  position: relative;
  z-index: 2;
  margin-top: 20vh;
}

#hard-places,
#room-service-container {
  margin-bottom: 20vh;
}

#multiple-services-container {
  margin-top: 15vh;
}

#room-service-container {
  margin-top: 30vh;
}
@media screen and (max-width: 800px) {
  #room-service-container {
    margin-top: 20vh;
    margin-bottom: 30vh;
  }
}

#order-from-anywhere {
  position: relative;
  width: 60vw;
  margin-left: 20vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  #order-from-anywhere {
    width: 90vw;
    margin-left: 5vw !important;
  }
}

#fluter-services-image {
  align-self: flex-end;
  width: auto;
  height: 85vh;
  max-height: 750px;
}

#menu-fluter-image {
  width: auto;
  height: 85vh;
  max-height: 750px;
}

#fluter-cart-image {
  width: auto;
  height: 85vh;
  max-height: 750px;
}

@media screen and (max-width: 800px) {
  #fluter-services-image {
    height: auto;
    width: 65vw;
    max-height: none;
    max-width: 300px;
  }

  #menu-fluter-image {
    height: auto;
    width: 65vw;
    max-height: none;
    max-width: 300px;
  }

  #fluter-cart-image {
    height: auto;
    width: 65vw;
    max-height: none;
    max-width: 300px;
  }
}

@media screen and (max-width: 500px) {
  #menu-fluter-image {
    height: auto;
    width: 50vw;
    max-height: none;
    max-width: 300px;
  }
}

.perfect-choice-explanation {
  width: 30vw;
  color: #000340;
  font-size: 24px;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .perfect-choice-explanation {
    width: 40vw;
    font-size: 20px;
  }
}
@media screen and (max-width: 380px) {
  .perfect-choice-explanation {
    font-size: 19px;
  }
}
@media screen and (max-width: 360px) {
  .perfect-choice-explanation {
    width: 40vw;
    font-size: 16.5px;
  }

  #hotel-demo-button,
  #cafe-demo-button {
    font-size: 14px;
  }
}
@media screen and (max-width: 300px) {
  .perfect-choice-explanation {
    width: 40vw;
    font-size: 14.5px;
  }

  #hotel-demo-button {
    font-size: 13.5px;
  }
}

.perfect-room-service-choice,
#multiple-services-title {
  width: 30vw;
  /* margin-right: 3vw; */
  color: #000340;
  font-size: 32px;
  font-weight: bold;
  /* text-align: center; */
}
@media screen and (max-width: 800px) {
  .perfect-room-service-choice,
  #multiple-services-title {
    width: 40vw;
    width: 80vw;
    font-size: 22px;
  }
}
@media screen and (max-width: 340px) {
  .perfect-room-service-choice {
    width: 40vw;
    width: 80vw;
    font-size: 22px;
  }

  #multiple-services-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 300px) {
  #multiple-services-title {
    font-size: 18px;
  }
}

.perfect-choice-description,
#multiple-services-description {
  width: 30vw;
  /* margin-right: 3vw; */
  color: #000340;
  color: #222;
  font-size: 18px;
  line-height: 35px;
  font-weight: 600;
  /* text-align: center; */
}
@media screen and (max-width: 800px) {
  .perfect-choice-description,
  #multiple-services-description {
    width: 42.5vw;
    width: 90vw;
    margin-bottom: 4vh;
    font-size: 16px;
  }
}
@media screen and (min-width: 500px) and (max-width: 600px) and (min-height: 700px) {
  .perfect-choice-description {
    margin-bottom: 10vh;
  }
}
@media screen and (max-width: 420px) and (min-height: 780px) {
  .perfect-choice-description {
    margin-bottom: 8vh;
    /* font-size: 14px; */
  }
}
@media screen and (max-width: 380px) {
  .perfect-choice-description {
    margin-bottom: 6vh;
    /* font-size: 14px; */
  }
}
@media screen and (max-width: 340px) {
  .perfect-choice-description {
    margin-bottom: 6vh;
  }
  .perfect-choice-description,
  #multiple-services-description {
    font-size: 14px;
  }
}
@media screen and (max-width: 310px) {
  .perfect-choice-description {
    margin-bottom: 9vh;
  }
  .perfect-choice-description,
  #multiple-services-description {
    font-size: 12px;
  }
}

#coffee-icon {
  position: absolute;
  left: 37vw;
  top: -7.5vh;
  height: 30vh;
  width: auto;
  transform: rotateZ(30deg);
}
@media screen and (max-width: 800px) {
  #coffee-icon {
    height: 17vh;
    left: 33.5vw;
    top: -5vh;
  }
}
@media screen and (min-width: 500px) and (max-width: 700px) and (max-height: 750px) {
  #coffee-icon {
    height: 21vh;
    left: 36.5vw;
    top: -5.5vh;
  }
}
@media screen and (max-width: 420px) and (max-height: 750px) {
  #coffee-icon {
    height: 18.5vh;
    left: 35vw;
    top: -5vh;
  }
}
@media screen and (max-width: 380px) {
  #coffee-icon {
    height: 17vh;
    left: 32.5vw;
    top: -5.5vh;
  }
}
@media screen and (max-width: 390px) and (max-height: 700px) {
  #coffee-icon {
    height: 17vh;
    left: 35vw;
    top: -5.5vh;
  }
}
@media screen and (max-width: 330px) {
  #coffee-icon {
    height: 18vh;
    left: 34vw;
    top: -5.5vh;
  }
}
@media screen and (max-width: 330px) and (max-height: 540px) {
  #coffee-icon {
    left: 35vw;
    top: -5.5vh;
  }
}
@media screen and (max-width: 300px) {
  #coffee-icon {
    height: 17vh;
    left: 27vw;
    top: -5.5vh;
  }
}

#blob-image {
  position: absolute;
  /* left: -220px; */
  left: -14.3vw;
  top: -150px;
  top: -35vh;
  height: 80vh;
  width: auto;
  transform: rotateZ(160deg);
  opacity: 0.9;
}
@media screen and (max-width: 800px) {
  #blob-image {
    height: 45vh;
    left: unset;
    /* right: -45.5vw; */
    /* transform: rotateZ(-50deg); */
    top: 30vh;
    top: -25vh;
    /* left: -180px; */
    left: -43.5vw;
  }
}

#room-service {
  position: relative;
  width: 60vw;
  margin-left: 20vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  #room-service {
    flex-direction: column;
    align-items: flex-start;
    width: 90vw;
    margin-left: 5vw !important;
  }
}

#burger-icon {
  position: absolute;
  left: 10vw;
  top: -12.5vh;
  height: 25vh;
  width: auto;
  transform: rotateZ(-30deg);
}
@media screen and (max-width: 1712px) {
  #burger-icon {
    height: 23vh;
  }
}

@media screen and (max-width: 1402px) {
  #burger-icon {
    height: 20vh;
  }
}

@media screen and (max-width: 1250px) {
  #burger-icon {
    height: 18vh;
  }

  .nav-item {
    margin-left: 5vw;
  }
}

@media screen and (max-width: 895px) {
  #burger-icon {
    height: 17vh;
  }
}

@media screen and (max-width: 800px) {
  #burger-icon {
    transform: rotateZ(-30deg);
    height: 17vh;
    top: -15vh;
    left: 65vw;
    top: -17vh;
  }
}
@media screen and (max-width: 300px) {
  #burger-icon {
    left: 61vw;
    top: -17vh;
  }
}

#blob2-image {
  position: absolute;
  right: 0;
  top: -150px;
  top: -24.2vh;
  height: 80vh;
  width: auto;
  transform: rotateZ(0);
  opacity: 1;
}

@media screen and (max-width: 1700px) {
  #blob2-image {
    height: 65vh;
  }
}

@media screen and (max-width: 1700px) and (max-height: 700px) {
  #blob2-image {
    top: -5vh;
    height: 75vh;
    right: 6vw;
  }
}

@media screen and (max-width: 1100px) {
  #blob2-image {
    height: 54vh;
  }
}

@media screen and (max-width: 980px) {
  #blob2-image {
    height: 48vh;
  }
}

@media screen and (max-width: 880px) {
  #blob2-image {
    height: 45vh;
  }
}

@media screen and (max-width: 800px) {
  #blob2-image {
    height: 50vh;
    top: 130px;
    top: 20.8vh;
    right: 10px;
    transform: rotateZ(180deg);
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) and (min-height: 500px) {
  #blob2-image {
    height: 50vh;
    top: 40vh;
    right: 6vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) and (min-height: 600px) {
  #blob2-image {
    height: 50vh;
    top: 35vh;
    right: 6vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) and (min-height: 700px) {
  #blob2-image {
    height: 50vh;
    top: 35vh;
    right: 6vw;
  }
}

@media screen and (min-width: 400px) and (max-width: 499px) and (min-height: 300px) {
  #blob2-image {
    height: 50vh;
    top: 60vh;
  }
}

@media screen and (min-width: 400px) and (max-width: 499px) and (min-height: 600px) {
  #blob2-image {
    height: 50vh;
    top: 48vh;
    right: 6vw;
  }
}

@media screen and (min-width: 400px) and (max-width: 499px) and (min-height: 700px) {
  #blob2-image {
    height: 50vh;
    top: 40vh;
    right: 6vw;
  }
}

@media screen and (min-width: 300px) and (max-width: 399px) and (min-height: 250px) {
  #blob2-image {
    height: 40vh;
    top: 95vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 399px) and (min-height: 400px) {
  #blob2-image {
    height: 40vh;
    top: 80vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 399px) and (min-height: 500px) {
  #blob2-image {
    height: 40vh;
    top: 70vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 399px) and (min-height: 600px) {
  #blob2-image {
    height: 40vh;
    top: 60vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 399px) and (min-height: 700px) {
  #blob2-image {
    height: 40vh;
    top: 50vh;
  }
}

@media screen and (min-width: 200px) and (max-width: 299px) and (min-height: 250px) {
  #blob2-image {
    height: 40vh;
    top: 100vh;
  }
}

@media screen and (min-width: 200px) and (max-width: 299px) and (min-height: 400px) {
  #blob2-image {
    height: 40vh;
    top: 95vh;
  }
}

@media screen and (min-width: 200px) and (max-width: 299px) and (min-height: 500px) {
  #blob2-image {
    height: 40vh;
    top: 85vh;
  }
}

@media screen and (min-width: 200px) and (max-width: 299px) and (min-height: 600px) {
  #blob2-image {
    height: 40vh;
    top: 70vh;
  }
}

@media screen and (min-width: 200px) and (max-width: 299px) and (min-height: 700px) {
  #blob2-image {
    height: 40vh;
    top: 60vh;
  }
}

#multiple-services {
  position: relative;
  width: 60vw;
  margin-left: 20vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  #multiple-services {
    flex-direction: column;
    align-items: flex-start;
    width: 90vw;
    margin-left: 5vw;
  }
}

#avocado-icon {
  position: absolute;
  left: 10vw;
  top: -12vh;
  height: 30vh;
  width: auto;
  transform: rotateZ(30deg);
}
@media screen and (max-width: 800px) {
  #avocado-icon {
    height: 19vh;
    left: unset;
    right: 4vw;
    /* right: 2vw; */
  }
}
@media screen and (max-width: 300px) {
  #avocado-icon {
    height: 17vh;
    right: 0vw;
  }
}

#donut-icon {
  position: absolute;
  left: 7vw;
  top: -10vh;
  height: 25vh;
  width: auto;
}

#blob3-image {
  position: absolute;
  /* left: 50px; */
  right: 3.3vw;
  bottom: -230px;
  bottom: 50px;
  bottom: 7vh;
  height: 80vh;
  width: auto;
  transform: rotateZ(45deg) rotateX(40deg);
  /* transform: rotateZ(120deg); */
  opacity: 1;
}
@media screen and (max-width: 800px) {
  #blob3-image {
    height: 52.5vh;
    bottom: 16vh;
    left: 0vw;
    transform: rotateZ(130deg) rotateX(180deg);
  }
}
@media screen and (min-width: 500px) and (max-width: 600px) and (min-height: 700px) {
  #blob3-image {
    height: 72.5vh;
    bottom: 25vh;
    left: 10vw;
  }
}
@media screen and (max-width: 470px) and (max-height: 750px) {
  #blob3-image {
    height: 60vh;
  }
}
@media screen and (max-width: 380px) and (min-height: 800px) {
  #blob3-image {
    height: 50vh;
    bottom: 13vh;
  }
}
@media screen and (max-width: 310px) {
  #blob3-image {
    height: 45vh;
  }
}

#why-fluter-title {
  padding-top: 20vh !important;
}

ul {
  position: relative;
  list-style-type: none;
  margin: auto;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  width: 70vw;
  z-index: 2 !important;
}

/* @media screen and (max-width: 1100px) {
  ul {
    width: 100vw;
    grid-template-columns: repeat(2, auto);
  }
} */

.perk-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22vw;
  margin-bottom: 10vh;
}

.perk-container > * {
  margin-bottom: 2vh;
}

@media screen and (max-width: 1370px) {
  ul {
    width: 100vw;
    grid-template-columns: repeat(2, auto);
  }

  .perk-container {
    width: 37vw;
    margin-bottom: 5vh;
  }
}

@media screen and (max-width: 810px) {
  ul {
    width: 100vw;
    grid-template-columns: repeat(1, auto);
  }

  .perk-container {
    width: 80vw;
    margin-bottom: 5vh;
  }
}

.bubble {
  height: 12vh;
  width: 12vh;
  min-width: 50px;
  min-height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;

  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.25), -2px 4px 8px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 800px) {
  .bubble {
    height: 9vh;
    width: 9vh;
  }
}

.bubble:hover {
  transform: scale(1.15, 1.15);
}

.bubble > * {
  color: white;
}

.bubble > * {
  height: 50%;
  width: auto;
}

#bubble1 {
  background: linear-gradient(
    135deg,
    rgba(254, 132, 100, 1) 0%,
    rgba(254, 110, 154, 0.5) 100%
  );
}

#bubble2 {
  background: linear-gradient(135deg, #4cdd30 0%, #dff8d5 170%);
}

#bubble3 {
  background: linear-gradient(135deg, #d65db1 0%, #845ec2 100%);
}

#bubble3 > img {
  height: 60%;
  width: auto;
}

#bubble5 {
  background: linear-gradient(135deg, #ff9671 0%, #ffc75f 100%);
}

#bubble4 {
  background: linear-gradient(135deg, #4a7e92 0%, #00c3ff 100%);
}

#bubble6 {
  background: linear-gradient(135deg, #ffa9ae 0%, #ffa4fc 100%);
}

.bubble-title {
  font-size: 18px;
  font-weight: 600;
  color: #38424d;
  text-align: center;
}
@media only screen and (min-width: 1800px) {
  .bubble-title {
    font-size: 20px;
  }
}

.bubble-subtitle {
  color: #747e88;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
@media only screen and (min-width: 1800px) {
  .bubble-subtitle {
    font-size: 18px;
  }
}

#wave {
  position: absolute;
  bottom: -12vh;
}
@media screen and (max-width: 800px) {
  #wave {
    height: 22vh;
    transform: translateX(-20vw) !important;
    bottom: -8vh;
  }
}
@media screen and (min-width: 500px) and (max-width: 600px) and (min-height: 700px) {
  #wave {
    height: 18vh;
    transform: translateX(-0vw) !important;
    bottom: -8vh;
  }
}

#how-it-works {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: linear-gradient(to bottom, #fff, #f3fdff); */
}

#how-it-works-title {
  margin-top: 20vh;
}
@media screen and (max-width: 800px) {
  #how-it-works-title {
    margin-left: 0;
  }
}

.step {
  width: 47.5vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: space-between; */
  margin-bottom: 100px;
  /* background: blue; */
}
@media screen and (min-width: 1920px) {
  .step {
    width: 45vw;
  }
}
@media screen and (max-width: 1400px) {
  .step {
    width: 50vw;
  }
}
@media screen and (max-width: 1280px) {
  .step {
    width: 52.5vw;
  }
}
@media screen and (max-width: 1000px) {
  .step {
    width: 55vw;
  }
}
@media screen and (max-width: 800px) {
  .step {
    width: 90vw;
  }
}

#step-right1,
#step-right2,
#step-right3 {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: blue; */
}

#step-right2 {
  margin-left: 7%;
}

#step1-left {
  margin-left: 15%;
}
@media screen and (max-width: 800px) {
  #step1-left {
    margin-left: 8%;
  }
}

#step3-left {
  margin-left: 5%;
}
@media screen and (min-width: 1920px) {
  #step3-left {
    margin-left: 1%;
  }
}
@media screen and (max-width: 1680px) {
  #step3-left {
    margin-left: 1%;
  }
}
@media screen and (max-width: 800px) {
  #step3-left {
    margin-left: 8%;
  }
}

.step-image {
  width: 20vw;
  height: auto;
}
@media screen and (max-width: 1400px) {
  .step-image {
    width: 23vw;
  }
}
@media screen and (max-width: 800px) {
  .step-image {
    width: 42vw;
  }
}
@media screen and (max-width: 320px) {
  .step-image {
    width: 47vw;
  }
}

.step-grid {
  display: grid;
  grid-gap: 10px 20px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}
@media screen and (max-width: 414px) {
  .step-grid {
    grid-gap: 7.5px 12px;
  }
}
@media screen and (max-width: 320px) {
  .step-grid {
    grid-gap: 5px 12px;
  }
}

.bullet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullet-icon {
  color: #000340;
  font-size: 20px;
}
@media screen and (min-width: 1920px) {
  .bullet-icon {
    font-size: 27px;
  }
}
@media screen and (min-width: 1680px) {
  .bullet-icon {
    font-size: 22px;
  }
}
@media screen and (max-width: 1180px) {
  .bullet-icon {
    font-size: 17px;
  }
}
@media screen and (max-width: 800px) {
  .bullet-icon {
    font-size: 15px;
  }
}
@media screen and (max-width: 320px) {
  .bullet-icon {
    font-size: 12px;
  }
}

.step-title {
  font-size: 35px;
  color: #000340;
  font-weight: bolder;
  margin: 0;
}
@media screen and (min-width: 1920px) {
  .step-title {
    font-size: 47px;
  }
}
@media screen and (min-width: 1680px) {
  .step-title {
    font-size: 42px;
  }
}
@media screen and (max-width: 1180px) {
  .step-title {
    font-size: 30px;
  }
}
@media screen and (max-width: 800px) {
  .step-title {
    font-size: 25px;
  }
}
@media screen and (max-width: 320px) {
  .step-title {
    font-size: 21px;
  }
}

.step-desc {
  color: #000340;
  font-size: 20px;
  grid-column-start: 2;
}
@media screen and (min-width: 1920px) {
  .step-desc {
    font-size: 27px;
  }
}
@media screen and (min-width: 1680px) {
  .step-desc {
    font-size: 23px;
  }
}
@media screen and (max-width: 1180px) {
  .step-desc {
    font-size: 17px;
  }
}
@media screen and (max-width: 800px) {
  .step-desc {
    font-size: 16px;
  }
}
@media screen and (max-width: 414px) {
  .step-desc {
    font-size: 15px;
  }
}
@media screen and (max-width: 320px) {
  .step-desc {
    font-size: 13px;
  }
}

#app-images {
  /* background: linear-gradient(to bottom, #b2ecf8, #fcefe8 80%); */
  background: #ffefc9;
  background: #fcefe8;
  padding-top: 17vh;
  margin-top: 8vh;
}

#app-images-title {
  margin: 0;
  margin-bottom: 0vh;
  width: 100vw;
  color: #198b4a;
  font-weight: 900;
  color: #38424d;
}
@media screen and (max-width: 800px) {
  #app-images-title {
    margin-bottom: 17vh;
  }
}

#images {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media screen and (max-width: 800px) {
  #images {
    flex-direction: column;
  }
}

.fluter-img {
  height: 45vh;
  width: auto;
  border-radius: 10%/5%;
}
@media screen and (max-width: 800px) {
  .fluter-img {
    height: auto;
    width: 50vw;
  }
}

#left-image {
  box-shadow: -8px 8px 4px rgba(0, 0, 0, 0.3);
}

#center-image {
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.3);
}

#right-image {
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.3);
}

#images > :nth-child(2) {
  margin-top: 20vh;
}
@media screen and (max-width: 800px) {
  #images > :nth-child(2) {
    margin-top: unset;
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-container > * {
  margin-bottom: 30px;
}
@media screen and (max-width: 800px) {
  .image-container > * {
    margin-bottom: 100px;
  }

  .image-container > h4 {
    margin-bottom: 30px;
  }
}

.image-container > h4 {
  text-align: center;

  color: #38424d;
  font-size: 18px;
  font-weight: 800;
}
@media only screen and (min-width: 1800px) {
  .image-container > h4 {
    font-size: 22px;
  }
}

#contact-us {
  display: flex;
  height: 90vh;
  min-height: 500px;
  width: 100vw;
  background: linear-gradient(to bottom, #ffffff, #e6e0da 40%, #ffffff);
}
@media screen and (max-width: 800px) {
  #contact-us {
    height: 120vh;
    flex-direction: column;
    min-height: 700px;
  }
}

#contact-us-text {
  height: 100%;
  width: 50vw;
  display: flex;
  align-items: center;
  margin: 0;
}
@media screen and (max-width: 800px) {
  #contact-us-text {
    height: 30%;
    justify-content: center;
    width: 100vw;
  }
}

#contact-us-text > h4 {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
  font-size: 32px;
  font-weight: 900;
  color: #38424d;
  line-height: 50px;
  width: 33vw;
  margin-left: 12vw;
}
@media screen and (min-width: 1800px) {
  #contact-us-text > h4 {
    font-size: 40px;
    line-height: 65px;
    margin-left: 12vw;
  }
}
@media screen and (max-width: 800px) {
  #contact-us-text > h4 {
    width: 80vw;
    font-size: 28px;
  }
}

@media screen and (max-width: 500px) {
  #contact-us-text {
    margin-top: 10px;
  }

  #contact-us-text > h4 {
    width: 86vw;
    font-size: 26px;
    margin-left: 5vw;
  }
}
@media screen and (max-width: 367px) {
  #contact-us-text > h4 {
    font-size: 24px;
  }
}

@media screen and (max-width: 340px) and (max-height: 680px) {
  #contact-us-text {
    margin-bottom: 20px;
  }
}

#contact-us-form {
  height: 100%;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #38424d;
}
@media screen and (max-width: 800px) {
  #contact-us-form {
    height: 70%;
    width: 100vw;
    align-items: flex-start;
  }
}

#form-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-width: 265px;
  width: 45%;
  min-height: 500px;
  height: 75%;
  background: white;
  border-radius: 24px;
  margin-right: 10vw;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

@media screen and (max-width: 1750px) {
  #form-container {
    width: 50%;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 1400px) {
  #form-container {
    width: 60%;
    margin-right: 0;
  }
}

@media screen and (max-width: 800px) {
  #form-container {
    width: 85%;
    height: 80%;
    margin-right: 0;
  }
}

.input-container {
  width: 80%;
  z-index: 2;
}

input {
  min-height: 30px;
  outline: none;
  padding: 5px 12px;
  border-radius: 16px;
  -webkit-appearance: none !important;
}
@media screen and (min-width: 1800px) {
  input {
    min-height: 35px;
  }
}
@media screen and (max-width: 800px) {
  input {
    font-size: 12px;
  }
}

#text-area-container {
  height: 20%;
}

.input-container > h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #38424d;
}

#name {
  width: 100%;
  background: #f7f7f7;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

#email {
  width: 100%;
  background: #f7f7f7;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

#phone-input {
  width: 100%;
  background: #f7f7f7;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

#message {
  width: 100%;
  height: 70%;
  background: #f7f7f7;
  resize: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 16px;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}
@media screen and (max-width: 800px) {
  #message {
    font-size: 12px;
  }
}

#send-button {
  background: #02bd5f;
  color: white;
  border-radius: 40px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  width: 80%;
  z-index: 2;
}
@media only screen and (min-width: 1800px) {
  #send-button {
    font-size: 16px;
  }
}

#contact-form-fluter-phone {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 900 !important;
  text-decoration: none !important;
}

@media screen and (max-width: 360px) {
  #contact-form-fluter-phone {
    font-size: 15px;
  }
}
@media screen and (max-width: 332px) {
  #contact-form-fluter-phone {
    font-size: 14px;
  }
}
@media screen and (max-width: 290px) {
  #contact-form-fluter-phone {
    font-size: 13px;
  }
}

.error {
  color: #f30;
  font-size: 1rem;
  width: 80%;
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 800px) {
  .error {
    font-size: 0.8rem;
  }
}

.success {
  color: #5cb85c;
  font-size: 1rem;
  width: 80%;
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 800px) {
  .success {
    font-size: 0.8rem;
  }
}

.formShape1 {
  top: -10px;
  left: -20px;
}
.formShape2 {
  top: 20%;
  left: unset;
  right: 15%;
}
.formShape3 {
  top: 35%;
}
.formShape4 {
  top: 55%;
  left: unset;
  right: 1%;
}
.formShape5 {
  top: 65%;
  left: 5%;
  height: 30px;
  width: 30px;
}
.formShape6 {
  top: 92%;
  left: 55%;
  height: 30px;
  width: 30px;
}

#footer {
  position: relative;
  width: 100vw;
  min-height: 300px;
  display: flex;
}
@media screen and (max-width: 800px) {
  #footer {
    flex-direction: column;
  }
}

#footer-logo-container {
  width: 40%;
  height: 100%;
  margin: 2% 0 50px 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  #footer-logo-container {
    width: 100%;
    height: unset;
    margin: 50px 0 0 0;
  }
}

#footer-logo-container > h4 {
  padding-left: 10vw;
}

#footer-logo {
  width: 100%;
  height: 100%;
}

#footer-logo > img {
  width: 60%;
  height: auto;
}

#footer-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #747e88;
}
@media screen and (max-width: 800px) {
  #footer-description {
    font-size: 15px;
  }
}

#footer-links {
  height: 100%;
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 35px;
  margin: 6% 0 50px 0;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  #footer-links {
    width: 85%;
    flex-direction: column;
    padding-top: 0px;
    margin-left: 10vw;
  }
}

#links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-left: 150px; */
}

#useful-links {
  /* margin-left: 150px; */
  margin-bottom: 25px;
  font-weight: 600;
  color: #38424d;
}
@media screen and (max-width: 800px) {
  #useful-links {
    font-size: 20px;
  }
}

#link-column1 {
  margin-right: 50px;
}

#link-column1 > *,
#link-column2 > * {
  margin-bottom: 17px;
  color: #747e88;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  #link-column1 > *,
  #link-column2 > * {
    font-size: 16px;
  }

  #link-column1,
  #link-column2 {
    margin-bottom: 25px;
  }
}

/* #link-column3 > * {
  cursor: unset;
} */

#phone,
#contact-email,
#facebook,
#instagram,
#street {
  display: flex;
}

#phone > h4,
#contact-email > h4,
#street > h4 {
  margin-bottom: 17px;
  margin-left: 13px;
  color: #747e88;
  font-size: 18px;
  font-weight: 500;
}

#facebook > a,
#instagram > a {
  margin-bottom: 17px;
  margin-left: 13px;
  font-size: 18px;
  font-weight: 500;
}

#facebook > img,
#instagram > img {
  width: 20px;
  object-fit: contain;
  margin-bottom: 16px;
}

@media screen and (max-width: 800px) {
  #phone > h4,
  #contact-email > h4,
  #street > h4,
  #facebook > a,
  #instagram > a {
    font-size: 15px;
  }
}

.icon {
  margin-top: 5px;
}
@media screen and (max-width: 800px) {
  .icon {
    margin-top: 3px;
  }
}

#link-column1 > *:hover,
#link-column2 > *:hover {
  color: #5cb85c;
}

/* #link-column2 {
  margin-left: 150px;
} */

.footer-shape {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

.footer-shape.shape-1 {
  position: absolute;
  background: -webkit-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: -o-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  left: 70px;
  opacity: 0.55;
}
@media (max-width: 767px) {
  .footer-shape.shape-1 {
    left: auto;
    right: 40px;
    top: 25%;
  }
}
.footer-shape.shape-2 {
  position: absolute;
  background: -webkit-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: -o-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: linear-gradient(#33c8c1 0%, #119bd2 100%);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  top: unset;
  right: unset;
  bottom: 40px !important;
  left: 15% !important;
  opacity: 0.55;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-shape.shape-2 {
    bottom: 85px;
  }
}
@media (max-width: 767px) {
  .footer-shape.shape-2 {
    bottom: 110px;
  }
}
.footer-shape.shape-3 {
  position: absolute;
  background: -webkit-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: -o-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: linear-gradient(#361cc1 0%, #2e82ef 100%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 35%;
  top: 40px;
  opacity: 0.55;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-shape.shape-3 {
    top: 40%;
  }
}
.footer-shape.shape-4 {
  position: absolute;
  background-color: #eba31a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 50%;
  bottom: 5px;
  opacity: 0.55;
}
.footer-shape.shape-5 {
  position: absolute;
  background-color: #e29e19;
  right: 37%;
  top: 0;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  opacity: 0.55;
}
.footer-shape.shape-6 {
  position: absolute;
  background-color: #b60d81;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 125px;
  right: 15%;
  opacity: 0.55;
}
.footer-shape.shape-7 {
  position: absolute;
  background-color: #6c63ff;
  width: 64px;
  height: 64px;
  bottom: 25px;
  right: 8%;
  opacity: 0.55;
  border-radius: 50%;
}

.footer-payments-row {
  background-color: #ffffff;
  margin-top: 26px;
}

.footer-payments-container {
  max-width: 1460px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: inherit;
  padding: 0 1rem;
}

.footer-payments-row-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0 0 1rem;
}

.footer-payments-row-inner > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  height: 1.75rem;
}

.footer-payments-row-inner > div:not(:last-child) {
  margin-right: 1rem;
}

.footer-payments-row-inner .logo-everypay {
  background-image: url(../../assets/images/logo-everypay.png);
  width: 6rem;
}

.footer-payments-row-inner .logo-visa {
  background-image: url(../../assets/images/logo-visa.svg);
  width: 3rem;
}

.footer-payments-row-inner .logo-mastercard {
  background-image: url(../../assets/images/logo-mastercard.png);
  width: 3rem;
  height: 1.5rem;
}

.footer-payments-row-inner .logo-maestro {
  background-image: url(../../assets/images/logo-maestro.svg);
  width: 3rem;
  height: 1.7rem;
}

.footer-payments-row-inner .logo-ticket-restaurant {
  background-image: url(../../assets/images/logo-ticket-restaurant.png);
  width: 6rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-shape.shape-7 {
    bottom: 20%;
  }
}
@media (max-width: 767px) {
  .footer-shape.shape-7 {
    bottom: 38%;
  }
}

@media (max-width: 800px) {
  #start-screen {
    flex-direction: column;
  }

  #copyright,
  #image {
    width: 100%;
    height: unset;
  }
}
</style>