<template>
  <div id="slide">
    <div class="store-image-container">
      <img
        class="store-image"
        :src="`https://fluter.fra1.cdn.digitaloceanspaces.com/assets/landing-page/logos/${image}`"
        alt
      />
    </div>
    <p class="store-name">{{ name }}</p>
  </div>
</template>

<script>
export default {
  name: "CarouselSlide",
  props: ["image", "name"]
};
</script>

<style scoped>
#slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.store-image-container {
  height: 100px;
  width: 100px;
  margin-bottom: 3vw;
  border: 3px solid #198b4a;
  box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.24), -3px 3px 6px rgba(0, 0, 0, 0.36);
  border-radius: 100%;
}
@media screen and (max-width: 800px) {
  .store-image-container {
    height: 80px;
    width: 80px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 310px) {
  .store-image-container {
    height: 70px;
    width: 70px;
    margin-bottom: 30px;
  }
}

.store-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.store-name {
  color: #198b4a;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .store-name {
    font-size: 17px;
  }
}
@media screen and (max-width: 310px) {
  .store-name {
    font-size: 14px;
  }
}
</style>